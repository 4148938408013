<template>
  <div class="form-errors">
    <ul class="form-errors__list">
      <li v-for="(errorMessage, index) in errorMessages" :key="`form-errors--${formId}-${index}`" class="form-errors__item">
        {{ errorMessage }}
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import { type WebformSubmissionValidationErrorFragment } from '#graphql-operations'

const props = defineProps<{
  formId: string
  formErrors: WebformSubmissionValidationErrorFragment[]
}>()

const errorMessages = computed(() => {
  return props.formErrors.map((error) => {
    return error.messages?.map((message) => {
      return message
    })
  }).flat()
})

</script>

<style lang="scss">
@use "~/assets/sass/tools";

.form-errors {
  @include tools.typo-small;
  color: var(--color-error);
}

</style>
