<template>
  <div>
    <div v-if="webformStructure" class="form" :class="classObject">
      <form
        ref="form"
        action=""
        class="form__form"
        role="form"
        @submit.prevent="onSubmit"
        @input="onInput"
      >
        <form-item
          v-for="element in webformStructure?.elements"
          :key="element.key"
          :form-errors="formErrors"
          validation-message="validation message"
          :element="element"
          :prefilled-inputs="prefilledInputs"
        />
      </form>
      <div v-if="formState === FormState.success" class="form__confirmation">
        Danke!
      </div>
      <FormErrors :form-errors="formErrors" :form-id="formId" />
    </div>
  </div>
</template>

<script setup lang="ts">
import FormErrors from './FormErrors.vue'
import { type WebformFragment, type WebformQuery, type WebformSubmissionMutation, type WebformSubmissionValidationErrorFragment } from '#graphql-operations'

interface FormDataElement {
  element: string,
  value: string | string[]
}

enum FormState {
  default,
  submitting,
  success,
  error,
}

export interface Props {
  formId: string
  prefilledInputs?: InputPrefill[]
}

const props = withDefaults(defineProps<Props>(), {
  prefilledInputs: () => {
    return []
  }
})

const form = ref<HTMLFormElement | null>()
const formErrors = ref<WebformSubmissionValidationErrorFragment[]>([])
const formState = ref(FormState.default)

const classObject = computed(() => ([`form--state-${FormState[formState.value]}`]))

const { data: webformStructure } = await useAsyncData<WebformFragment>(`webform:${props.formId}`, async () => {
  const data: WebformQuery = await useGraphqlQuery('webform', { id: props.formId }).then((v) => {
    return v.data
  })
  return data?.webformById
})

const webformStructureFlattened = flattenWebformStructure(webformStructure.value)

const multiValueFieldTypes = ['checkboxes']

function flattenWebformStructure (webformStructure: WebformFragment): array {
  if (webformStructure.elements === undefined) {
    return []
  }
  let elements = [...webformStructure.elements]
  elements.forEach((element) => {
    if (element.elements) {
      elements = [...elements, ...flattenWebformStructure(element)]
    }
  })
  return elements
}

function onSubmit (event: SubmitEvent) {
  if (form.value === null || typeof form.value === 'undefined') {
    return
  }
  const validity = form.value.reportValidity()
  if (validity) {
    const formData = new FormData(form.value, event.submitter)
    submitForm(formData)
  }
}

function onInput () {
  if (formState.value === FormState.success) {
    formState.value = FormState.default
  }
}

async function submitForm (formData: FormData): Promise<void> {
  if (formState.value === FormState.submitting) {
    return
  }

  formState.value = FormState.submitting

  const elements = getFormData(formData)

  const data: WebformSubmissionMutation = await useGraphqlMutation('webformSubmission', { id: props.formId, elements }).then((v) => {
    return v.data
  })

  if (data.submitWebform.errors?.length) {
    formState.value = FormState.error
    alert('Error!')
    return
  }

  if (data.submitWebform.validationErrors?.length) {
    formErrors.value = data.submitWebform.validationErrors
    formState.value = FormState.error
    return
  }
  handleSubmitSuccess()
}

function handleSubmitSuccess (): void {
  formErrors.value = []
  formState.value = FormState.success
  form.value?.reset()
}

function getFormData (formData: FormData): FormDataElement[] {
  const elements: FormDataElement[] = []
  for (const item of formData.entries()) {
    const elementName = item[0]
    const value = item[1]
    const webformElement = webformStructureFlattened.find(element => element.key === elementName)

    if (multiValueFieldTypes.includes(webformElement.type)) {
      const existingElementIndex = elements.findIndex(element => element.element === elementName)
      if (existingElementIndex !== -1) {
        elements[existingElementIndex].value.push(value)
      } else {
        const element: FormDataElement = {
          element: elementName,
          value: [value.toString()]
        }
        elements.push(element)
      }
    } else {
      const element: FormDataElement = {
        element: elementName,
        value: value.toString()
      }
      elements.push(element)
    }
  }
  return elements
}

</script>

<style lang="scss" scoped>
@use "~/assets/sass/tools";

.form {
  padding: var(--grid-gutter);
  border: var(--border);
  border-radius: var(--border-radius);

  &--state-submitting {
    opacity: 0.5;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__confirmation {
    margin-top: var(--grid-gutter);
    padding: 0 0.333em;
    border: var(--border-width) solid #3a633a;
    border-radius: var(--border-radius);
    color: var(--color-text);
    background-color: #ceebce;

  }
}
</style>
